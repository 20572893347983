import Header from "../../components/commons/Header";

//-----------Content-------------------
import Client from "../../components/user/clients";
import Services from "../../components/user/services";
import Project from "../../components/user/project";
import Blogs from "../../components/user/blog/mainBlog";
import Footer from "../../components/commons/Footer";
import LandingPage from "../../components/user/landingPage2";
import AboutUs from "../../components/user/aboutUs";
import Contact from "../../components/user/contact";
import WhyChoseUs from "../../components/user/whyChoseUs";
import Whatspp from "../../components/user/whatsapp"


export default function Main() {
  return (
    <>
      <Header></Header>
      <Whatspp></Whatspp>
      <LandingPage></LandingPage>
      <AboutUs></AboutUs>
      <Services></Services>
      <Contact></Contact>
      <Project></Project>
      <Client></Client>
      <WhyChoseUs></WhyChoseUs>
      <Blogs></Blogs>
      <Footer></Footer>
    </>
  );
}
