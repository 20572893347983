import React, { useState } from "react";
import { handleAddBlog } from "./addBlogHandler";
import { placeholderImage, isValidUrl } from "../../invalidImage";
import { Modal, Button, Tooltip, Whisper } from "rsuite";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Styles for the editor

const ModalBlog = ({ closeModal }) => {
  const [title, setTitle] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [description, setDescription] = useState("");
  const [author, setAuthor] = useState("Admin");
  const [showTitleTooltip, setShowTitleTooltip] = useState(false);
  const [showImageUrlTooltip, setShowImageUrlTooltip] = useState(false);
  const [showAuthorTooltip, setShowAuthorTooltip] = useState(false);

  const titleTooltip = <Tooltip>Maximum 100 characters allowed</Tooltip>;
  const imageUrlTooltip = <Tooltip>Maximum 200 characters allowed</Tooltip>;
  const authorTooltip = <Tooltip>Maximum 50 characters allowed</Tooltip>;

  const handleTitleChange = (e) => {
    const value = e.target.value;
    setTitle(value);
    setShowTitleTooltip(value.length >= 100);
  };

  const handleImageUrlChange = (e) => {
    const value = e.target.value;
    setImageUrl(value);
    setShowImageUrlTooltip(value.length >= 200);
  };

  const handleAuthorChange = (e) => {
    const value = e.target.value;
    setAuthor(value);
    setShowAuthorTooltip(value.length >= 50);
  };

  const handleDescriptionChange = (value) => {
    setDescription(value);
  };

  return (
    <Modal open={true} onClose={closeModal} size="md">
      <Modal.Header>
        <Modal.Title>Add New Blog</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          onSubmit={(e) =>
            handleAddBlog(e, title, imageUrl, description, author, closeModal)
          }
        >
          <div className="mb-4">
            <label htmlFor="title" className="block mb-2">
              Title
            </label>
            <Whisper
              trigger="active"
              placement="top"
              speaker={titleTooltip}
              open={showTitleTooltip}
            >
              <input
                type="text"
                id="title"
                maxLength="100"
                value={title}
                onChange={handleTitleChange}
                className="w-full p-2 border rounded"
                placeholder="Enter blog title"
              />
            </Whisper>
          </div>
          <div className="mb-4">
            <label htmlFor="imageUrl" className="block mb-2">
              Image URL
            </label>
            <Whisper
              trigger="active"
              placement="top"
              speaker={imageUrlTooltip}
              open={showImageUrlTooltip}
            >
              <input
                type="text"
                id="imageUrl"
                maxLength="200"
                value={imageUrl}
                onChange={handleImageUrlChange}
                className="w-full p-2 border rounded"
                placeholder="Enter image URL"
              />
            </Whisper>
            {imageUrl && (
              <div className="mt-4">
                <img
                  src={isValidUrl(imageUrl) ? imageUrl : placeholderImage}
                  alt="Selected"
                  className="rounded-lg object-contain h-full w-full"
                />
              </div>
            )}
          </div>
          <div className="mb-4">
            <label htmlFor="description" className="block mb-2">
              Description
            </label>
            <ReactQuill
              value={description}
              onChange={handleDescriptionChange}
              className="bg-white"
              theme="snow"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="author" className="block mb-2">
              Author
            </label>
            <Whisper
              trigger="active"
              placement="top"
              speaker={authorTooltip}
              open={showAuthorTooltip}
            >
              <input
                type="text"
                id="author"
                maxLength="50"
                value={author}
                onChange={handleAuthorChange}
                className="w-full p-2 border rounded"
                placeholder="Enter author's name"
              />
            </Whisper>
          </div>
          <div className="flex justify-end">
            <Button type="submit" appearance="primary">
              Add Blog
            </Button>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={closeModal} appearance="subtle">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalBlog;
