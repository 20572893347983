import React from "react";
import about2 from "../../assets/about2.jpeg";
import about3 from "../../assets/about3.jpeg";

const AboutUs = () => {
  return (
    <section className="flex flex-col md:flex-row justify-between items-center p-6 md:p-16 bg-white" id="AboutUs">
      <div className="flex-1 mb-8 md:mb-0 md:mr-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <img src={about3} alt="Team working" className="w-full h-96 sm:h-72 object-cover rounded-md self-center" />
          <img src={about2} alt="Team brainstorming" className="w-full object-cover h-60 sm:h-72 rounded-md" style={{ height: "500px" }} />
        </div>
      </div>
      <div className="flex-1 md:ml-8">
        <h5 style={{ fontFamily: "Oswald, sans-serif", fontWeight: 600, fontSize: "18px", lineHeight: "50px" }}>Tentang Kami</h5>
        <h2 className="md:text-2xl text-3xl md:leading-normal text-gray-900 mb-4 md:mb-6" style={{ fontFamily: "Oswald, sans-serif", fontWeight: 500 }}>
        Didorong oleh semangat dan keahlian dalam bidang kami
        </h2>
        <div
          style={{
            fontFamily: "Overpass, sans-serif",
            fontStyle: "normal",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "30px",
          }}
        >
          <p className="text-gray-600 mb-4 text-justify">
            Defra adalah perusahaan yang menyediakan layanan perizinan limbah cair di Indonesia, mencakup analisis lingkungan dan pengurusan dokumen perizinan. Kami memastikan kepatuhan terhadap regulasi dengan dukungan tim ahli
            berpengalaman.
          </p>
          <p className="text-gray-600 text-justify">Komitmen kami adalah membantu industri beroperasi secara efisien dan ramah lingkungan. Hubungi kami untuk layanan perizinan limbah cair yang cepat dan tepat.</p>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
