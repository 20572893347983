import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import logo from "../../assets/logo/defra.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-sky-600 shadow dark:bg-sky-600 mt-0">
      <div className="w-full max-w-7xl mx-auto p-4 md:py-8">
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
          <div className="flex flex-col sm:flex-row items-center mb-4 sm:mb-0">
            <img
              src={logo}
              className="h-16 w-auto sm:h-20 sm:w-auto px-2"
              alt="Flowbite Logo"
            />
            <span
              className="text-center sm:text-left text-2xl font-semibold whitespace-nowrap text-black mt-2 sm:mt-0 sm:ml-4"
              style={{ fontFamily: "Oswald, sans-serif" }}
            >
              Defra
              <br />
              <Link
                className="text-sm font-medium text-white dark:text-gray-400"
                style={{ fontFamily: "Oswald, sans-serif" }}
              >
                PT. Defra Water Technology
              </Link>
            </span>
          </div>
          <ul
            className="flex flex-col sm:flex-row sm:space-x-4 items-center text-sm font-medium text-white dark:text-gray-400"
            style={{ fontFamily: "Oswald, sans-serif" }}
          >
            <li>
              <Link to="/#AboutUs" className="hover:underline mb-2 sm:mb-0">
                About
              </Link>
            </li>
            <li>
              <Link to="/#mapsDefra" className="hover:underline mb-2 sm:mb-0">
                Calling Us
              </Link>
            </li>
            <li>
              <Link to="/#mapsDefra" className="hover:underline">
                Maps
              </Link>
            </li>
          </ul>
        </div>
        <div className="flex justify-center space-x-8 mt-4">
          <Link
            to="https://facebook.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white"
          >
            <FontAwesomeIcon icon={faFacebook} size="2x" />
          </Link>
          <Link
            to="https://twitter.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white"
          >
            <FontAwesomeIcon icon={faTwitter} size="2x" />
          </Link>
          <Link
            to="https://instagram.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white"
          >
            <FontAwesomeIcon icon={faInstagram} size="2x" />
          </Link>
          <Link
            to="https://linkedin.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white"
          >
            <FontAwesomeIcon icon={faLinkedin} size="2x" />
          </Link>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-6" />
        <center>
          <span
            className="block text-sm text-white sm:text-center dark:text-white"
            style={{ fontFamily: "Oswald, sans-serif" }}
          >
            © 2024{" "}
            <Link to="" className="hover:underline">
              Deha Web Design™
            </Link>
            . All Rights Reserved.
          </span>
        </center>
      </div>
    </footer>
  );
};

export default Footer;
