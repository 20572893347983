import React, { useState } from "react";
import { handleUpdate } from "./updateClientHandler";
import TutorialGambar from "../../imageTutorial";
import { placeholderImage, isValidUrl } from "../../invalidImage";
import { Tooltip, Whisper } from "rsuite";

const ModalUpdateClient = ({ client, onClose }) => {
  const [imageUrl, setImageUrl] = useState(client.image);
  const [name, setName] = useState(client.name);
  const [showNameTooltip, setShowNameTooltip] = useState(false);
  const [showImageUrlTooltip, setShowImageUrlTooltip] = useState(false);

  const nameTooltip = <Tooltip>Maximum 50 characters allowed</Tooltip>;
  const imageUrlTooltip = <Tooltip>Maximum 200 characters allowed</Tooltip>;

  const handleNameChange = (e) => {
    const value = e.target.value;
    setName(value);
    setShowNameTooltip(value.length >= 50);
  };

  const handleImageUrlChange = (e) => {
    const value = e.target.value;
    setImageUrl(value);
    setShowImageUrlTooltip(value.length >= 200);
  };

  return (
    <div>
      <div
        id="crud-modal"
        aria-hidden="true"
        className="fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-full overflow-y-auto overflow-x-hidden bg-black bg-opacity-50"
      >
        <div className="relative p-4 w-full max-w-4xl max-h-full">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                Update Client
              </h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={onClose}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <form
              className="p-4 md:p-5"
              onSubmit={(e) =>
                handleUpdate(e, client.id, name, imageUrl, onClose)
              }
            >
              <div className="grid gap-4 mb-4 grid-cols-1 md:grid-cols-2">
                <div>
                  <div className="col-span-2">
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Name
                    </label>
                    <Whisper
                      trigger="active"
                      placement="top"
                      speaker={nameTooltip}
                      open={showNameTooltip}
                    >
                      <input
                        type="text"
                        name="name"
                        id="name"
                        maxLength="50"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="Type client name"
                        value={name}
                        onChange={handleNameChange}
                      />
                    </Whisper>
                  </div>
                  <div className="col-span-2">
                    <label
                      htmlFor="imageUrl"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Image URL
                    </label>
                    <Whisper
                      trigger="active"
                      placement="top"
                      speaker={imageUrlTooltip}
                      open={showImageUrlTooltip}
                    >
                      <input
                        type="text"
                        name="imageUrl"
                        id="imageUrl"
                        maxLength="200"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="Paste image URL here"
                        value={imageUrl}
                        onChange={handleImageUrlChange}
                      />
                    </Whisper>
                  </div>
                  {imageUrl && (
                    <div className="relative mt-3 h-36">
                      <img
                        src={isValidUrl(imageUrl) ? imageUrl : placeholderImage}
                        alt="Selected"
                        className="rounded-lg object-contain h-full w-full"
                      />
                    </div>
                  )}
                  <button
                    type="submit"
                    className="mt-4 text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    <svg
                      className="me-1 -ms-1 w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 5a1 1 0 011 1v3h3a1 1 110 2h-3v3a1 1 11-2 0v-3H6a1 1 110-2h3V6a1 1 111-1z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    Update client
                  </button>
                </div>
                <TutorialGambar />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalUpdateClient;
