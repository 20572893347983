import logo from "../../assets/logo/defra.png";

const Footer = () => {
  return (
    <footer className="bg-sky-600 shadow dark:bg-sky-600 mt-0">
      <div className="w-full max-w-7xl mx-auto p-4 md:py-8">
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-center">
          <div className="flex flex-col sm:flex-row items-center mb-4 sm:mb-0">
            <img src={logo} className="h-16 w-auto sm:h-20 sm:w-auto px-2" alt="Flowbite Logo" />
            <span className="text-center sm:text-left text-2xl font-semibold whitespace-nowrap text-black mt-2 sm:mt-0 sm:ml-4" style={{ fontFamily: "Oswald, sans-serif" }}>
              Defra
              <br />
              <a className="text-sm font-medium text-white dark:text-gray-400" style={{ fontFamily: "Oswald, sans-serif" }}>
                PT. Defra Water Technology
              </a>
            </span>
          </div>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-6" />
        <center>
          <span className="block text-sm text-white sm:text-center dark:text-white" style={{ fontFamily: "Oswald, sans-serif" }}>
            © 2024{" "}
            <a href="" className="hover:underline">
              Deha Web Design™
            </a>
            . All Rights Reserved.
          </span>
        </center>
      </div>
    </footer>
  );
};

export default Footer;
