import React from "react";
import waterImage from "../../assets/about5.jpeg"; // Sesuaikan dengan path gambar Anda

const LandingPage = () => {
  const handleClick = () => {
    window.location.href =
      "https://api.whatsapp.com/send/?phone=6281319259694&text&type=phone_number&app_absent=0";
  };

  // Fungsi untuk scroll ke bagian Our Services
  const scrollToServices = () => {
    const element = document.getElementById("OurServices");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <section
      className="relative min-h-screen flex flex-col-reverse lg:flex-row justify-between items-center pt-32 sm:pt-32 md:pt-32 lg:pt-32 xl:pt-20 p-2 lg:p-16 bg-white overflow-hidden "
      id="LandingPage"
    >
      {/* Background Image */}
      <div className="absolute inset-0 z-0">
        <img
          src={waterImage}
          alt="Pengolahan Limbah Cair"
          className="w-full h-full object-cover"
          style={{ filter: "brightness(0.4)" }} // Efek gambar lebih gelap untuk keseluruhan background
        />
      </div>

      {/* Bagian teks di sebelah kiri */}
      <div className="relative z-10 flex-1 order-2 lg:order-1 text-white ml-5 md:ml-20">
        <p className="text-xs sm:text-xs lg:text-base font-light uppercase tracking-widest my-3">
          PENTINGNYA AKSES AIR BERSIH DAN LINGKUNGAN YANG SEHAT
        </p>
        <h1 className="text-2xl sm:text-3xl lg:text-3xl font-bold leading-snug lg:leading-tight max-w-full lg:max-w-2xl  my-5">
          Menyediakan air bersih yang layak dan aman serta pengelolaan limbah
          cair dan sanitasi
        </h1>
        <p className="sm:text-sm lg:text-lg max-w-full lg:max-w-3xl pb-4 my-3">
          Kami berkomitmen menyediakan solusi terbaik dalam penyediaan teknologi
          air bersih dan pengolahan limbah yang efektif dan ramah lingkungan.
          Bersama-sama, kita bisa menjaga kebersihan air demi masyarakat yang
          lebih sehat dan lingkungan yang lebih baik.
        </p>

        <div className="flex flex-wrap gap-4">
          {/* Button untuk scroll ke Our Services */}
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={scrollToServices}
          >
            Layanan Kami
          </button>
          {/* Button untuk konsultasi gratis */}
          <button
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
            onClick={handleClick}
          >
            Konsultasi Gratis
          </button>
        </div>
      </div>

      {/* Bagian gambar di sebelah kanan */}
      <div className="relative z-10 flex-1  order-1 lg:order-2 py-10 md:pt-3">
        <div className="grid grid-cols-1 gap-10">
          {/* Gambar pertama */}
          <img
            src="https://sustainability.psu.edu/wp-content/uploads/2022/10/SDGLOGO.png"
            alt="Sustainable Development Goals"
            className="w-full h-48 sm:h-48 md:h-48 lg:h-48 object-contain rounded-md self-center"
          />
          {/* Gambar kedua */}
          <img
            src="https://quidgest.com/wp-content/uploads/2020/12/sdg-6-1.png"
            alt="Sustainable Development Goals"
            className="w-full h-48 sm:h-48 md:h-48 lg:h-48 object-contain rounded-md"
          />
        </div>
      </div>
    </section>
  );
};

export default LandingPage;
