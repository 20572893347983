import React, { useEffect, useState } from "react";
import { fetchServices } from "../../api/servicesAPI";
import { placeholderImage, isValidUrl } from "../invalidImage";

export default function ServicesPage() {
  const [services, setServices] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getServices = async () => {
      try {
        const servicesData = await fetchServices();
        if (Array.isArray(servicesData)) {
          setServices(servicesData);
        } else {
          throw new Error("Services data is not an array");
        }
      } catch (error) {
        setError(error.message);
      }
    };

    getServices();
  }, []);

  return (
    <div
      className="min-h-screen flex flex-col items-center justify-center bg-gray-100"
      id="OurServices"
    >
      <div className="w-full max-w-8xl p-3 md:p-10">
        <h1 className="text-4xl font-bold text-center mb-10 font-oswald text-black">
          Layanan Kami
        </h1>
        <p
          className="mx-auto max-w-5xl text-base text-center text-black"
          style={{ fontFamily: "Overpass" }}
        >
          Kami menyediakan layanan perizinan lingkungan, termasuk penyusunan
          AMDAL, UKL/UPL, pengelolaan limbah cair, dan pemantauan kepatuhan,
          mendukung bisnis Anda agar sesuai dengan standar lingkungan{" "}
        </p>
        <div className="flex flex-col items-center gap-6 pt-6">
          {services.map((service) => (
            <div
              key={service.id}
              className={`relative rounded-lg overflow-hidden flex flex-col sm:flex-row ${
                service.id % 2 === 0 ? "sm:flex-row-reverse" : "sm:flex-row"
              } items-center`}
              style={{
                backgroundImage: `url(${
                  isValidUrl(service.image) ? service.image : placeholderImage
                })`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: "450px", // Pastikan tingginya cukup agar gambar terlihat
                width: "100%", // Sesuaikan lebar dengan container
              }}
            >
              <div
                className={`absolute inset-0 flex flex-col justify-end text-justify p-7 ${
                  service.id % 2 === 0
                    ? "items-end text-right"
                    : "items-start text-left"
                }`}
                style={{
                  background: `linear-gradient(${
                    service.id % 2 === 0 ? "to left" : "to right"
                  }, rgba(0, 0, 0, 0.9), transparent)`,
                }}
              >
                <h3 className="text-white text-2xl font-medium mb-2 font-oswald shadow-lg">
                  {service.name}
                </h3>
                <p className="text-white text-sm font-overpass max-w-xl mb-8">
                  {service.description}
                </p>
              </div>
            </div>
          ))}
        </div>
        {error && <div className="text-red-500 mt-4">{error}</div>}
      </div>
    </div>
  );
}
