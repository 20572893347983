import { useState, useEffect } from "react";
import logo from "../../assets/logo/defra.png";
import { Link } from "react-router-dom";

export default function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleaClick = () => {
    setMobileMenuOpen(false);
  };

  return (
    <header
      className={`fixed w-full z-50 font-overpass text-xl transition-all duration-300 ${
        scrollPosition > 0 ? "bg-white shadow-md" : "bg-transparent"
      }`} // Tambahkan kelas rounded-lg di sini
    >
      <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8">
        <div className="flex lg:flex-1">
          <Link to="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Your Company</span>
            <img
              width={scrollPosition > 0 ? 120 : 200}
              height={scrollPosition > 0 ? 120 : 200}
              className={`transition-all duration-300 ${
                scrollPosition > 0 ? "h-16" : "h-20"
              } w-auto`}
              src={logo}
              alt="Company Logo"
            />
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className={`h-6 w-6 ${
                scrollPosition > 0 ? "text-gray-900" : "text-black"
              }`}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-7">
          <Link
            to="/#AboutUs"
            className={`text-base font-semibold leading-6 no-underline hover:no-underline ${
              scrollPosition > 0 ? "text-gray-900" : "text-black"
            }`}
          >
            Tentang
          </Link>
          <Link
            to="/#OurServices"
            className={`text-base font-semibold leading-6 no-underline hover:no-underline ${
              scrollPosition > 0 ? "text-gray-900" : "text-black"
            }`}
          >
            Layanan
          </Link>
          <Link
            to="/#OurAdvantages"
            className={`text-base font-semibold leading-6 no-underline hover:no-underline ${
              scrollPosition > 0 ? "text-gray-900" : "text-black"
            }`}
          >
            Kelebihan
          </Link>
          <h1
            className={`text-2xl font-bold font-oswald-header leading-6 mx-5 mb ${
              scrollPosition > 0 ? "text-gray-900" : "text-black"
            }`}
          >
            DEFRA WATER
          </h1>

          <Link
            to="/#ProjectsPage"
            className={`text-base font-semibold leading-6 no-underline hover:no-underline ${
              scrollPosition > 0 ? "text-gray-900" : "text-black"
            }`}
          >
            Project
          </Link>
          <Link
            to="/#ClientsPage"
            className={`text-base font-semibold leading-6 no-underline hover:no-underline ${
              scrollPosition > 0 ? "text-gray-900" : "text-black"
            }`}
          >
            Klien
          </Link>
          <Link
            to="/blog"
            className={`text-base font-semibold leading-6 no-underline hover:no-underline ${
              scrollPosition > 0 ? "text-gray-900" : "text-black"
            }`}
          >
            Blog
          </Link>
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <Link
            to="/login"
            className={`text-lg font-semibold leading-6 ${
              scrollPosition > 0 ? "text-gray-900" : "text-black"
            }`}
          >
            Log in <span aria-hidden="true">&rarr;</span>
          </Link>
        </div>
      </nav>
      <div className="lg:hidden">
        {mobileMenuOpen && (
          <div className="fixed inset-0 z-50 bg-white p-6">
            <div className="flex items-center justify-between">
              <Link to="/" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img
                  width={120}
                  height={120}
                  className="h-8 w-auto"
                  src={logo}
                  alt="Company Logo"
                />
              </Link>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="mt-6 heading">
              <Link
                to="/#AboutUs"
                className="block rounded-lg px-3 py-2 text-lg font-semibold leading-7 text-gray-900 hover:bg-gray-50 no-underline hover:no-underline"
                onClick={handleaClick}
              >
                Tentang
              </Link>
              <Link
                to="/#OurServices"
                className="block rounded-lg px-3 py-2 text-lg font-semibold leading-7 text-gray-900 hover:bg-gray-50 no-underline hover:no-underline"
                onClick={handleaClick}
              >
                Layanan
              </Link>
              <Link
                to="/#OurAdvantages"
                className="block rounded-lg px-3 py-2 text-lg font-semibold leading-7 text-gray-900 hover:bg-gray-50 no-underline hover:no-underline"
                onClick={handleaClick}
              >
                Kelebihan
              </Link>
              <Link
                to="/#ProjectsPage"
                className="block rounded-lg px-3 py-2 text-lg font-semibold leading-7 text-gray-900 hover:bg-gray-50 no-underline hover:no-underline"
                onClick={handleaClick}
              >
                Project
              </Link>
              <Link
                to="/#ClientsPage"
                className="block rounded-lg px-3 py-2 text-lg font-semibold leading-7 text-gray-900 hover:bg-gray-50 no-underline hover:no-underline"
                onClick={handleaClick}
              >
                Klien
              </Link>
              <Link
                to="/blog"
                className="block rounded-lg px-3 py-2 text-lg font-semibold leading-7 text-gray-900 hover:bg-gray-50 no-underline hover:no-underline"
                onClick={handleaClick}
              >
                Blog
              </Link>
              <Link
                to="/login"
                className="block rounded-lg px-3 py-2 text-lg font-semibold leading-7 text-gray-900 hover:bg-gray-50 no-underline hover:no-underline"
                onClick={handleaClick}
              >
                Log in
              </Link>
            </div>
          </div>
        )}
      </div>
    </header>
  );
}
