import React from "react";
import ReactDOM from "react-dom/client";
import "rsuite/dist/rsuite.min.css"; // Import CSS RSuite
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from 'react-router-dom';
import Auth0ProviderWithNavigate from './components/Auth0ProviderWithNavigate';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <Auth0ProviderWithNavigate>
        <App />
      </Auth0ProviderWithNavigate>
    </Router>
  </React.StrictMode>
);

reportWebVitals();
