import React, { useEffect, useState } from "react";
import { fetchBlogs } from "../../../api/blogsAPI";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { placeholderImage, isValidUrl } from "../../../components/invalidImage";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

function NextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="absolute top-1/2 md:-right-6 -right-7 transform -translate-y-1/2 p-2 bg-gray-800 text-white cursor-pointer z-10 rounded-full" // Ubah ke '-right-8' untuk menambah jarak keluar
      onClick={onClick}
    >
      <i className="fas fa-chevron-right"></i>
    </div>
  );
}

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="absolute top-1/2 md:-left-6 -left-7 transform -translate-y-1/2 p-2 bg-gray-800 text-white cursor-pointer z-10 rounded-full" // Ubah ke '-left-8' untuk menambah jarak keluar
      onClick={onClick}
    >
      <i className="fas fa-chevron-left"></i>
    </div>
  );
}


const BlogsPage = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getBlogs = async () => {
      try {
        const blogsData = await fetchBlogs();
        setBlogs(blogsData);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    getBlogs();
  }, []);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  // Membagi blogs menjadi chunk untuk ditampilkan dalam slider
  const chunkedBlogs = [];
  for (let i = 0; i < blogs.length; i += 2) {
    chunkedBlogs.push(blogs.slice(i, i + 2));
  }

  return (
    <div className="relative px-6" id="Blog">
      <div className="flex flex-wrap justify-center gap-8 px-6 mt-6" id="BlogsPage">
        <div className="flex flex-col sm:flex-row items-center justify-between w-screen mx-0 md:mx-16">
          <h2 className="text-2xl font-bold text-gray-800 mb-2 sm:mb-0 heading font-oswald">
           Pilihan Blog Terbaik
          </h2>
          <div className="flex-grow mx-4 border-t border-gray-300 w-full sm:w-auto sm:border-t-4 sm:border-l sm:h-0 sm:ml-4 sm:mr-4"></div>
          <a href="/blog" className="text-blue-600 hover:underline py-5 font-overpass">
            Jelajahi Defra Blog
          </a>
        </div>
      </div>
      {loading ? (
        <p className="text-center">Loading...</p>
      ) : error ? (
        <p className="text-center text-red-500">Error: {error}</p>
      ) : (
        <Slider {...settings} className="mx-5 my-3">
          {chunkedBlogs.map((chunk, index) => (
            <div key={index} className="p-4">
              <div className="flex flex-wrap gap-8 justify-center pb-10">
                {chunk.map((blog) => (
                  <Link
                    to={`/blog/${blog.id}`}
                    key={blog.id}
                    className="hover:no-underline flex flex-col md:flex-row gap-4 max-w-xl items-stretch border border-gray-300 rounded-lg shadow-lg p-3"
                  >
                    <img
                      src={isValidUrl(blog.image) ? blog.image : placeholderImage}
                      alt={blog.title}
                      className="w-60 h-60 object-cover rounded mx-auto md:mx-0"
                    />
                    <div className="flex flex-col justify-between">
                      <div>
                        <h2
                          className="text-xl font-medium w-64 text-justify"
                          style={{ fontFamily: "Oswald, sans-serif" }}
                        >
                          {blog.title}
                        </h2>
                        <div
                          className="w-60 text-justify pt-4"
                          style={{ fontFamily: "Overpass" }}
                          dangerouslySetInnerHTML={{
                            __html:
                              blog.description.length > 100
                                ? `${blog.description.substring(0, 150)}...`
                                : blog.description,
                          }}
                        />
                      </div>
                      <div className="text-gray-600 flex mt-auto">
                        {blog.author} |{" "}
                        {new Date(blog.created_at).toLocaleDateString("en-US", {
                          month: "long",
                          day: "numeric",
                          year: "numeric",
                        })}
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          ))}
        </Slider>
      )}
    </div>
  );
};

export default BlogsPage;
