import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../components/commons/Sidebar";
import DashboardC from "../../components/admin/dashboard";

const Dashboard = () => {
  const navigate = useNavigate();

  const refreshAccessToken = async () => {
    try {
      const refreshToken = localStorage.getItem("refreshToken");
      const response = await fetch("https://backend.defra.co.id/refresh-token", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ refreshToken }),
      });

      if (!response.ok) {
        throw new Error("Failed to refresh token");
      }

      const data = await response.json();
      localStorage.setItem("accessToken", data.accessToken);
    } catch (error) {
      console.error("Error refreshing access token:", error);
      window.location.href = "/login";
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      refreshAccessToken();
    } else {
      // Token exists, continue to the dashboard
      // You might also want to check token expiry here
    }
  }, [navigate]);

  return (
    <div className="flex flex-col md:flex-row min-h-screen bg-gray-900">
      <Sidebar />
      <DashboardC />
    </div>
  );
};

export default Dashboard;
