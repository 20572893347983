import { updateProject } from "../../../api/projectAPI";
import Swal from 'sweetalert2';

export const handleUpdateProject = async (
  e,
  id,
  title,
  imageUrl,
  description,
  toggleModal
) => {
  e.preventDefault();

  // Validasi semua field harus terisi
  if (!title || !imageUrl || !description) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Title, Image URL, and Description are required',
    });
    return;
  }

  // Siapkan payload update
  const updatedFields = {
    title,
    image: imageUrl,
    description,
  };

  const confirmation = await Swal.fire({
    title: 'Are you sure?',
    text: "You are about to update the project's information.",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, update it!'
  });

  if (!confirmation.isConfirmed) {
    return;
  }

  try {
    Swal.fire({
      title: 'Loading...',
      text: 'Please wait while we update the project.',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    await updateProject(id, updatedFields);

    Swal.fire({
      icon: 'success',
      title: 'Success',
      text: 'Project updated successfully',
    }).then(() => {
      window.location.reload();
    });

    toggleModal();
  } catch (error) {
    console.error("Failed to update project:", error);
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'Failed to update project',
    });
  }
};
