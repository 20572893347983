import React from "react";
import environment from "../../assets/logo/environment.svg";
import handshakeIcon from "../../assets/logo/handshake.svg";
import price from "../../assets/logo/price.svg";
import team from "../../assets/logo/team.svg";
import experience from "../../assets/logo/experience.svg";
import quality from "../../assets/logo/quality.svg";
import layanan  from "../../assets/about7.jpeg"

const data = [
  {
    title: "Pengalaman dan Keahlian",
    text: "Berpengalaman pengelolaan limbah cair di berbagai industri.",
    imageUrl: experience,
  },
  {
    title: "Pelayanan Terpadu",
    text: "Mengelola perizinan lingkungan, konsultasi dan dukungan teknis yang tepat",
    imageUrl: handshakeIcon,
  },
  {
    title: "Komitmen terhadap Kualitas",
    text: "Standar tinggi dan pengawasan ketat untuk hasil terbaik",
    imageUrl: quality,
  },
  {
    title: "Keberlanjutan dan Kepedulian Lingkungan",
    text: "Berfokus pada solusi berkelanjutan pelestarian lingkungan.",
    imageUrl: environment,
  },
  {
    title: "Tim yang Berdedikasi",
    text: "Tim profesional, berdedikasi dan komitmen dalam pelayanan terbaik.",
    imageUrl: team,
  },
  {
    title: "Harga Kompetitif",
    text: "Penawaran dengan harga bersaing dan transparan.",
    imageUrl: price,
  },
];

const WhyChooseUs = () => {
  return (
    <div className="flex flex-col lg:flex-row items-center justify-center my-20 space-y-10 lg:space-y-0 lg:space-x-10" id="OurAdvantages">
      {/* Left Image Section */}
      <div className="w-full lg:w-6/12">
        <img src={layanan} alt="Farm" className="w-full h-auto md:ps-6 ps-0" />
      </div>

      {/* Right Text Section */}
      <div className="w-full lg:w-7/12 px-6 lg:px-10">
        <h2 className="uppercase text-sm font-medium mb-2" style={{ fontFamily: "Oswald, sans-serif", color: "#295F98" }}>
          Kenapa harus memilih kami
        </h2>
        <h1 className="text-3xl font-semibold mb-4" style={{ fontFamily: "Oswald, sans-serif" }}>
        Layanan Pengolahan Limbah Cair Profesional{" "}
        </h1>
        <p className="text-gray-700 mb-4" style={{ fontFamily: "Overpass" }}>
        Kami menyediakan solusi lengkap dalam pengolahan limbah cair, mulai dari desain instalasi, pengelolaan operasional, hingga pemantauan kualitas air. Dukung bisnis Anda dengan layanan terpercaya yang sesuai standar lingkungan dan regulasi pemerintah.       </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 mt-6">
          {data.map((item, index) => (
            <div key={index} className="flex items-start space-x-4">
              <img src={item.imageUrl} alt={item.title} className="w-12 h-12" />
              <div>
                <h4 className="text-xl font-medium" style={{ fontFamily: "Oswald, sans-serif" }}>
                  {item.title}
                </h4>
                <p className="text-gray-600 text-sm" style={{ fontFamily: "Overpass" }}>
                  {item.text}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
