import React from "react";
import { Grid, Row, Col } from "rsuite";
import contact from "../../assets/contact.jpg";

const Contact = () => {

  const handleClick = () => {
    window.location.href =
      "https://api.whatsapp.com/send/?phone=6282223968588&text&type=phone_number&app_absent=0";
  };

  const handleEmailClick = () => {
    window.location.href = "mailto:dwihandaya@gmail.com";
  };

  return (
    <div className="relative py-20 px-5 md:py-20 md:px-20">
      {/* Background Image */}
      <div className="absolute inset-0 z-0">
        <img
          src={contact}
          alt="Contact Background"
          className="w-full h-full object-cover"
        />
      </div>
      {/* Dark Overlay */}
      <div className="absolute inset-0 bg-cyan-700 opacity-80 z-10"></div>
      <Grid fluid className="relative z-20">
        <Row
          className="show-grid flex-col md:flex-row"
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Col
            xs={24}
            md={12}
            className="flex items-center justify-center"
            style={{ padding: 0 }}
          >
            <div
              className="rounded-l-lg shadow-lg p-6 lg:py-24 lg:px-10 w-full lg:h-[500px]"
              style={{
                backgroundColor: "#96C9F4",
                height: "100%",
                position: "relative",
                zIndex: 1,
              }}
            >
              <h1
                className="text-4xl lg:text-5xl font-medium mb-4"
                style={{ fontFamily: "Oswald, sans-serif" }}
              >
                Ayo Terhubung dengan Kami!{" "}
              </h1>
              <p
                className="mb-8 text-lg lg:text-base text-justify"
                style={{ fontFamily: "Overpass, sans-serif" }}
              >
                Kami siap membantu Anda. Hubungi kami untuk konsultasi lebih
                lanjut mengenai layanan perizinan lingkungan atau pertanyaan
                lainnya. Jangan ragu, kami ada di sini untuk Anda!{" "}
              </p>
              <div className="flex space-x-4">
                <button
                  className="bg-white text-pink-500 font-medium py-2 px-4 rounded-full flex items-center"
                  style={{ fontFamily: "Oswald, sans-serif" }} onClick={handleClick}
                >
                  <img
                    src="https://img.icons8.com/color/48/000000/whatsapp--v1.png"
                    alt="WhatsApp Icon"
                    className="w-5 h-5 lg:w-6 lg:h-6 mr-2"
                  />
                  WHATSAPP NOW
                </button>
                <button
                  className="bg-white text-pink-500 font-medium py-2 px-4 rounded-full flex items-center"
                  style={{ fontFamily: "Oswald, sans-serif" }} onClick={handleEmailClick}
                >
                  <img
                    src="https://img.icons8.com/color/48/000000/email--v1.png"
                    alt="Email Icon"
                    className="w-5 h-5 lg:w-6 lg:h-6 mr-2"
                  />
                  EMAIL US
                </button>
              </div>
            </div>
          </Col>
          <Col xs={24} md={12} style={{ padding: 0 }} id="mapsDefra">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d541.859979311932!2d106.6640422433548!3d-6.344622747961242!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69e50013194a1d%3A0x94660fb10d6737bd!2sPT.%20DEFRA%20WATER%20TECHNOLOGY!5e0!3m2!1sen!2sid!4v1727399248505!5m2!1sen!2sid"
              width="100%"
              height="500"
              allowFullScreen=""
              loading="lazy"
              className="w-full h-[500px] rounded"
              style={{ position: "relative", zIndex: 0 }}
            ></iframe>
          
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export default Contact;
