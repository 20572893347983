import { insertClient } from "../../../api/clientAPI";
import Swal from "sweetalert2";

export const handleSubmit = async (e, name, imageUrl, toggleModal) => {
  e.preventDefault();

  if (!name || !imageUrl) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Name and Image URL are required",
    });
    return;
  }

  try {
    Swal.fire({
      title: "Loading...",
      text: "Please wait while we add the client.",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    await insertClient({ name, image: imageUrl });

    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Client added successfully",
    }).then(() => {
      window.location.reload(); // Reload the page after showing the success message
    });

    toggleModal(); // Close the modal
  } catch (error) {
    console.error("Failed to add client:", error);
    Swal.fire({
      icon: "error",
      title: "Error",
      text: "Failed to add client",
    });
  }
};
