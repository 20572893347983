import React, { useEffect, useState } from "react";
import { placeholderImage, isValidUrl } from "../../components/invalidImage";
import { fetchProjects } from "../../api/projectAPI";

const DialogXXL = ({ isOpen, onClose }) => {
  const [projects, setProjects] = useState([]);
  const [error, setError] = useState(null);
  const [visible, setVisible] = useState(false);
  const [show, setShow] = useState(isOpen);

  useEffect(() => {
    const getProjects = async () => {
      try {
        const projectsData = await fetchProjects();
        if (Array.isArray(projectsData)) {
          setProjects(projectsData);
        } else {
          throw new Error("Projects data is not an array");
        }
      } catch (error) {
        setError(error.message);
      }
    };

    getProjects();
  }, []);

  useEffect(() => {
    if (isOpen) {
      setShow(true);
      setTimeout(() => setVisible(true), 10); // Delay to trigger transition
      document.body.style.overflow = "hidden";
    } else {
      setVisible(false);
      setTimeout(() => setShow(false), 300); // Match the CSS transition duration
      document.body.style.overflow = "auto";
    }
  }, [isOpen]);

  return (
    <div>
      {show && (
        <div className={`bg-white fixed inset-0 z-50 flex items-center justify-center backdrop-blur-xl transition-opacity duration-300 ${visible ? "opacity-100" : "opacity-0"}`}>
          <div className="relative w-screen h-screen font-light leading-relaxed antialiased overflow-y-auto text-black z-10">
            <button onClick={onClose} className="fixed top-2 right-2 font-bold me-8" style={{ fontFamily: "Oswald, sans-serif" }}>
              &times; Back
            </button>
            {error && <div className="text-red-500">{error}</div>}
            {projects.map((project, index) => (
              <div key={project.id} className={`mx-auto flex flex-col md:flex-row gap-8 py-10 px-4 max-w-7xl mt-5 ${index % 2 !== 0 ? "md:flex-row-reverse" : ""}`}>
                <img src={isValidUrl(project.image) ? project.image : placeholderImage} alt={project.title} className="rounded-lg w-full md:w-1/2 object-cover" style={{ height: "300px" }} />
                <div className="w-full md:w-1/2 px-4 md:px-10 self-center">
                  <p className="text-sm font-semibold" style={{ fontFamily: "Oswald, sans-serif" }}>
                    OUR PORTFOLIO
                  </p>
                  <p className="text-3xl mt-3 font-medium" style={{ fontFamily: "Oswald, sans-serif" }}>
                    {project.title}
                  </p>
                  <p className="text-lg mt-5">{project.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DialogXXL;
