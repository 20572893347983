import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2"; // Import SweetAlert2

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const checkTokenExpiry = () => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      const payload = JSON.parse(atob(accessToken.split(".")[1]));
      const expiry = payload.exp * 1000;
      const now = new Date().getTime();

      if (now > expiry) {
        // Token expired, remove tokens and navigate to login
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        Swal.fire({
          icon: "warning",
          title: "Session Expired",
          text: "Please login again.",
          confirmButtonText: "OK",
        }).then(() => {
          navigate("/login");
        });
      }
    }
  };

  const refreshAccessToken = async () => {
    try {
      const refreshToken = localStorage.getItem("refreshToken");
      const response = await fetch(
        "https://backend.defra.co.id/refresh-token",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ refreshToken }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to refresh token");
      }

      const data = await response.json();
      localStorage.setItem("accessToken", data.accessToken);
      setLoading(false); // Set loading to false after successful token refresh
    } catch (error) {
      console.error("Error refreshing access token:", error);
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      Swal.fire({
        icon: "error",
        title: "Session Expired",
        text: "Please login again.",
        confirmButtonText: "OK",
      }).then(() => {
        navigate("/login");
      });
    }
  };

  useEffect(() => {
    checkTokenExpiry();

    // Set interval to check token expiry every 10 seconds
    const intervalId = setInterval(() => {
      checkTokenExpiry();
    }, 1000); //

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, [navigate]);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (!accessToken) {
      refreshAccessToken();
    } else {
      setLoading(false); // Set loading to false if token exists
    }
  }, [navigate]);

  if (loading) {
    return <div>Loading...</div>; // Show a loading indicator while processing
  }

  return localStorage.getItem("accessToken") ? children : null;
};

export default ProtectedRoute;
