import React, { useState } from "react";
import { insertComment } from "../../../api/commentAPI";
import Swal from "sweetalert2";
import { Tooltip, Whisper } from "rsuite";

const CommentSection = ({ blog, setBlog }) => {
  const [newComment, setNewComment] = useState("");
  const [newName, setNewName] = useState("");
  const [showNameTooltip, setShowNameTooltip] = useState(false);
  const [showCommentTooltip, setShowCommentTooltip] = useState(false);

  // Tooltip content
  const nameTooltip = <Tooltip>Maximum 50 characters allowed</Tooltip>;
  const commentTooltip = <Tooltip>Maximum 150 characters allowed</Tooltip>;

  const handleNameChange = (e) => {
    const value = e.target.value;
    setNewName(value);
    // Show tooltip if the character count reaches 50
    setShowNameTooltip(value.length >= 50);
  };

  const handleCommentChange = (e) => {
    const value = e.target.value;
    setNewComment(value);
    // Show tooltip if the character count reaches 150
    setShowCommentTooltip(value.length >= 150);
  };

  const handleInsertComment = async () => {
    if (newComment.trim() === "" || newName.trim() === "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Comment and Name cannot be empty",
      });
      return;
    }

    // Check character length constraints
    if (newName.length > 50) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Name cannot exceed 50 characters",
      });
      return;
    }

    if (newComment.length > 150) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Comment cannot exceed 150 characters",
      });
      return;
    }

    try {
      const commentData = {
        comment: newComment,
        blog_id: blog.id,
        name: newName,
      };
      const insertedComment = await insertComment(commentData);

      if (!insertedComment.created_at) {
        insertedComment.created_at = new Date().toISOString();
      }

      setBlog((prevBlog) => {
        if (prevBlog) {
          return {
            ...prevBlog,
            comments: [...prevBlog.comments, insertedComment],
          };
        }
        return prevBlog;
      });
      setNewComment("");
      setNewName("");

      // Reload the page after successful comment insertion
      window.location.reload();
    } catch (error) {
      console.error("Failed to insert comment:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to add comment",
      });
    }
  };

  return (
    <div className="container p-4 max-w-screen-lg text-black">
      <h2 className="text-2xl font-bold mb-4">Comments</h2>
      {blog.comments.length > 0 ? (
        blog.comments.map((comment) => (
          <div
            key={comment.id}
            className="block max-w-full p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 mb-4"
          >
            <div className="flex items-center mb-2">
              <img
                src={`https://picsum.photos/seed/${comment.id}/50`}
                className="rounded-full w-12 h-12 mr-3"
                alt=""
              />
              <div>
                <h5 className="text-lg font-bold tracking-tight text-gray-900 dark:text-white">
                  {comment.name}
                </h5>
                <p className="text-gray-600 dark:text-gray-400 text-sm">
                  {new Date(comment.created_at).toLocaleString()}
                </p>
              </div>
            </div>
            <p className="font-normal text-gray-700 dark:text-gray-400 pt-5 break-words">
              {comment.comment}
            </p>
          </div>
        ))
      ) : (
        <p>No comments yet.</p>
      )}
      <div className="mt-6">
        <h3 className="text-xl font-bold mb-2">Add a Comment</h3>
        <p className="text-black font-semibold pb-2">Name</p>
        <Whisper
          trigger="active"
          placement="top"
          speaker={nameTooltip}
          open={showNameTooltip}
        >
          <input
            type="text"
            className="border p-2 w-full mb-2 rounded-lg"
            placeholder="Your Name"
            value={newName}
            maxLength={50} // Set maximum length for name
            onChange={handleNameChange}
          />
        </Whisper>
        <p className="text-black font-semibold pb-2">Comment</p>
        <Whisper
          trigger="active"
          placement="top"
          speaker={commentTooltip}
          open={showCommentTooltip}
        >
          <textarea
            className="border p-2 w-full mb-2 rounded-lg"
            placeholder="Your Comment"
            value={newComment}
            maxLength={150} // Set maximum length for comment
            onChange={handleCommentChange}
          ></textarea>
        </Whisper>
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded-lg"
          onClick={handleInsertComment}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default CommentSection;
